// This file contains the GLOBAL styles for Handsontable.

.handsontable {
  padding-bottom: 10px !important;
}

.handsontable .htDimmed {
  background-color: #eee;
}

.htComments {
  margin-left: 5px;
}

.htCommentsContainer .htCommentTextArea {
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.handsontable td.htInvalid {
  background-color: #f44336 !important;
}
