// This file contains global util classes that can be added to any element. 
// Only globally useful helpers should be added to this file. If something is specific to a component
// it should be added as a component level style instead.
// 
// All these helper classes should be prefixed with `g-` to help identify them.

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-spacer {
  flex: 1 1 auto;
}

.spacer {
  flex: 1 1 auto;
}

.no-margin {
  margin: 0;
}

.g-cursor-pointer {
  cursor: pointer;
}

.g-bold {
  font-weight: bold;
}

.g-hidden {
  display: none;
}