// This is the main entry point of our global styles.
// You can add global styles to this file, and also import other files.
// If your use-case is well-defined then you should define a different file and include it here
// instead of defining everything here in one big file.
@use 'material-theme';
@use 'material-overrides';
@use 'handsontable-overrides';
@use 'utils-classes';
@use 'sass:color';

html, body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
